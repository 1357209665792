import { Icon, Input } from 'antd';
import { ChangeEvent } from 'react';

import { tNamespaced } from 'utils/i18nUtil';

import './PipelineDetailsSearchInput.scss';
import { usePipelineDetailsSearchParameters } from '../PipelineDetails/PipelineDetails.hooks';

const tn = tNamespaced('PipelineDetailsTable');

export const PipelineDetailsSearchInput = () => {
  const { values: searchParams, updateSearchParams } = usePipelineDetailsSearchParameters();

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const params = [{ name: 'search', value: e.target.value }];

    updateSearchParams(params);
  };

  return (
    <div className="pipeline-details-search-input">
      <Icon className="pipeline-details-search-input__icon" type="search" />
      <Input
        className="pipeline-details-search-input__search"
        value={searchParams.search}
        onChange={handleSearch}
        placeholder={tn('search_placeholder')}
      />
    </div>
  );
};
